import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import Moment from 'react-moment';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { TailSpin, Watch } from  'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//API
import api from '../../settings/AxiosSetup';

//Components
import Header from '../../components/nav/header';
import BottomMenu from '../../components/nav/bottomMenu';
import Greeting from '../../components/greeting/greeting';
import RegisterTimeForm from '../../components/forms/registerTimeForm';
import DayRow from '../../components/item/dayrow';
import DayRowSkeleton from '../../components/item/dayrowSkeleton';
import ModalModify from '../../components/modal/modalModify';
import Artwork from '../../components/artwork/artwork';

const Home = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        setInterval(() => setDate(new Date()), 30000);
        getRegistrations(moment().format('DD-MM-YYYY'));
    }, []);

    //Variables
    const [date, setDate] = useState(Date.now());
    const [submitDate, setSubmitDate] = useState(moment().format('DD-MM-YYYY'));
    const [registerDate, setRegisterDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateLabel, setDateLabel] = useState(moment().format('LL'));
    const [registrationObject, setRegistrationObject] = useState([]);
    const [totals, setTotals] = useState('');
    const [loadingRegistrations, setLoadingRegistrations] = useState(false);
    const [showSkeletonsRegistrations, setShowSkeletonsRegistrations] = useState(true);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [afgeboekt, setAfgeboekt] = useState(false);

    //Modal variables
    const [showModal, setShowModal] = useState(0);
    const [modalData, setModalData] = useState([]);

    //Get user registrations
    const getRegistrations = async (date, type) => {
        setLoadingRegistrations(true);
        if (type === 'skeleton') { setShowSkeletonsRegistrations(true) }
        setTotals('');
        try {
            const response = await api.get('api/v2/registraties?datum=' + date);
            // Success
            if (response.data.success) {
                setRegistrationObject(response.data.data);
                setTotals((Math.round(response.data.totaal_uren * 100) / 100).toFixed(2));
                setAfgeboekt(response.data.afgeboekt);
                setOpenBackdrop(false);
                setShowModal(false);
                setShowSkeletonsRegistrations(false);
                setLoadingRegistrations(false);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }

    const handleChangeDate = (value) => {
        setRegisterDate(moment(value.$d).format('YYYY-MM-DD'));
        setDateLabel(moment(value.$d).format('LL'));
        getRegistrations(moment(value.$d).format('DD-MM-YYYY'), 'skeleton');
        setSubmitDate(moment(value.$d).format('DD-MM-YYYY'));
    }


    const deleteRow = async (id, index) => {
        setOpenBackdrop(true);
        //Call API
        try {
            const response = await api.post('api/v2/registratie-verwijderen?id=' + id + '&rij=' + index);
            // Success
            if (response.data.success) {
                getRegistrations(submitDate);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }

    const renderModal = (id, index, date, locationId, clientId, bouwnummerId, dekId, hourTypeId, overnachting_eten, aantal_uren, opmerking) => {
        const obj = {
            id: id,
            index: index,
            date: date,
            location: locationId,
            client: clientId,
            buildingnumber: bouwnummerId,
            dek: dekId,
            hourtype: hourTypeId,
            overnachting_eten: overnachting_eten,
            opmerking: opmerking,
            aantal_uren: aantal_uren
        }
        setModalData(obj);
        setShowModal(showModal + 1);
    }

    const submitDay = async () => {
        setOpenBackdrop(true);
        try {
            const response = await api.post('api/v2/dag-afboeken?id=' + registrationObject[0].id);
            // Success
            if (response.data.success) {
                getRegistrations(submitDate);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }

    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <div className="heading">
                            <h1><Greeting /></h1>
                            <span className="time">
                                <Icon.Clock /><Moment format="HH:mm">{date}</Moment>
                            </span>
                        </div>
                        <div className="register">
                            <div className="column-register">
                                <RegisterTimeForm 
                                    registerDate={registerDate}
                                    changeDate={handleChangeDate}
                                    submitDate={submitDate}
                                    afgeboekt={afgeboekt}
                                    getRegistrations={getRegistrations}
                                    setOpenBackdrop={setOpenBackdrop}
                                />
                            </div>
                            
                            {/* Registered hours by day */}
                            <div className="days">
                                <div className="days-heading">
                                    <h3>Day state {dateLabel}</h3>
                                    {
                                        loadingRegistrations ?
                                        <Watch
                                            height="20"
                                            width="20"
                                            radius="48"
                                            color="white"
                                            ariaLabel="watch-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                        :
                                        null
                                    }
                                </div>
                                <div className="day-rows">
                                    {
                                        showSkeletonsRegistrations ?
                                            <div className="skeletons-hours">
                                                <DayRowSkeleton />
                                                <DayRowSkeleton />
                                                <DayRowSkeleton /> 
                                            </div>
                                        :
                                        <div>
                                        {
                                            registrationObject.length > 0 ?
                                            registrationObject[0].rijen.map((option, index) => {
                                                return <DayRow 
                                                key={index}
                                                row={index}
                                                date={submitDate}
                                                afgeboekt={afgeboekt}
                                                id={registrationObject[0].id} 
                                                overnachting_eten={registrationObject[0].overnachting_eten} 
                                                aantal_uren={option.aantal_uren} 
                                                dek={option.dek}
                                                bouwnummer={option.bouwnummer}
                                                klant={option.klant}
                                                locatie={option.locatie}
                                                urensoort={option.urensoort}
                                                opmerking={option.opmerking}
                                                totaal_uren={registrationObject[0].totaal_uren}
                                                deleteRow={deleteRow}
                                                renderModal={renderModal} />
                                            })
                                            :
                                            <p className="no-registrations">No results</p>
                                        }
                                        </div>
                                    }
                                </div>
                                {
                                    totals && registrationObject.length > 0 ?
                                    <div className="day-actions">
                                        <div className="buttons">
                                            {
                                                afgeboekt ?
                                                <p>Day closed</p>
                                                :
                                                <div>
                                                    {
                                                        totals !== '0.00' ?
                                                        <button className="action-button" onClick={submitDay}>
                                                            <span>Offer hours</span>
                                                        </button>
                                                        :
                                                        null
                                                    }
                                               
                                                </div>
                                            }
                                        </div>
                                        <div className="totals">
                                            <p>{totals}</p>
                                            {
                                                !afgeboekt ?
                                                <Icon.ThreeDotsVertical />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <Artwork />
                        </div>
                    </div>
                </div>
            </div>

            {/* Component bottom menu */}
            <BottomMenu />

            {/* Backdrop */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                <Watch
                    height="80"
                    width="80"
                    radius="48"
                    color="white"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Backdrop>

            {/* Modal to modify registration line */}
            {
                showModal > 0 ?
                <ModalModify show={showModal} data={modalData} getRegistrations={getRegistrations} />
                :
                null
            }
        </div>
    );
}

export default Home;