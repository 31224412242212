import React, { useState, useEffect } from 'react';

//3rd party
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from  'react-loader-spinner';

//Components
import Datepicker from '../datepicker/datepicker';
import Dropdown from './dropdown';
import DropdownGhost from './dropdownGhost';

//API
import api from '../../settings/AxiosSetup';

//Styles
import './css/timeform.css';
import { notification } from 'antd';

const RegisterTimeForm = (props) => {

    //Props
    const registerDate = props.registerDate;
    const changeDate = props.changeDate;
    const submitDate = props.submitDate;
    const getRegistrations = props.getRegistrations;
    const setOpenBackdrop = props.setOpenBackdrop;
    const afgeboekt = props.afgeboekt;
    const dataToEdit = props.dataToEdit;

    const loggedIn = localStorage.getItem('ingelogd');
    useEffect(() => {
        if (loggedIn === 'true') { 
            getOptions(); 
        }
    }, []);

    //Variables
    const [data, setData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [allFormOptions, setAllFormOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [location, setLocation] = useState(dataToEdit ? dataToEdit.location : '');
    const [clientOptions, setClientOptions] = useState([]);
    const [client, setClient] = useState(dataToEdit ? dataToEdit.client : '');
    const [buildingNumbersOptions, setBuildingNumbersOptions] = useState([]);
    const [buildingNumber, setBuildingNumber] = useState(dataToEdit ? dataToEdit.buildingnumber : '');
    const [dekOptions, setDekOptions] = useState([]);
    const [dekOption, setDekOption] = useState(dataToEdit ? dataToEdit.dek : '');
    const [hourTypeOptions, setHourTypeOptions] = useState([]);
    const [hourType, setHourType] = useState(dataToEdit ? dataToEdit.hourtype : '');
    const [stayAndFoodOptions, setStayAndFoodOptions] = useState([
        {
            value: 'geen',
            label: 'Geen'
        },
        {
            value: 'eten',
            label: 'Eten'
        },
        {
            value: 'eten_nacht',
            label: 'Eten/nacht'
        }
    ]);
    const [stayAndFood, setStayAndFood] = useState(dataToEdit ? dataToEdit.overnachting_eten : '');
    const [note, setNote] = useState(dataToEdit ? dataToEdit.opmerking : '');
    const [workedHours, setWorkedHours] = useState(dataToEdit ? dataToEdit.aantal_uren : '');
    const [newRegistrationObject, setNewRegistrationObject] = useState(
    dataToEdit ? 
    {klant:Number(dataToEdit.client),locatie:Number(dataToEdit.location),bouwnummer:Number(dataToEdit.buildingnumber),dek:Number(dataToEdit.dek),uren_soort:Number(dataToEdit.hourtype),aantal_uren:dataToEdit.aantal_uren,opmerking:dataToEdit.opmerking} 
    : 
    {klant:0,locatie:0,bouwnummer:0,dek:0,uren_soort:0,aantal_uren:0,opmerking:0}
    );

    //Functions

    const notification = (type, content) => {
        if (type === 'error') {
            toast.error(content, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            toast.success(content, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    //Fill array with options
    const setDropdownOptions = (data) => {
        var array = [];
        data.map((option, index) => {
            const obj = {
                value: option.value,
                label: option.label,
                inactief: option.inactief
            }
            array.push(obj);
        })
        return array;
    }

    const returnModifiedArray = (data) => {
        const obj = {
            value: data.value,
            label: data.label,
            inactief: data.inactief
        }
        return obj;
    }

    const setDropdownOptionsNew = (locations) => {
        var arrayLocations = [];
        var arrayClients = [];
        var arrayBuildingNumbers = [];
        var arrayDekken = [];
        var arrayUrensoorten = [];
        locations.map((location, index) => {
            arrayLocations.push(returnModifiedArray(location));

            location.klanten.map((client, index) => {
                arrayClients.push(returnModifiedArray(client));

                client.bouwnummers.map((bouwnummer, index) => {
                    arrayBuildingNumbers.push(returnModifiedArray(bouwnummer));
                })
                client.dekken.map((dek, index) => {
                    arrayDekken.push(returnModifiedArray(dek));
                })
                client.urensoorten.map((urensoort, index) => {
                    arrayUrensoorten.push(returnModifiedArray(urensoort));
                })
            })
        })
        setLocationOptions(arrayLocations);
        setClientOptions(arrayClients);
        setBuildingNumbersOptions(arrayBuildingNumbers);
        setDekOptions(arrayDekken);
        setHourTypeOptions(arrayUrensoorten);
    }

    //Get field data via API
    const getOptions = async () => {
        try {
            const response = await api.get('api/v2/locaties');
            // Success
            if (response.data.success) {
                setData(response.data.data);
                const locations = response.data.data;
                setDropdownOptionsNew(locations);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }

    const handleDateChange = (value) => {
        changeDate(value);
    }

    //Empty values because parent options has changed
    const emptyValues = (types) => {
        types.map((option, index) => {
            if (option === 'location') { setLocation('') }
            if (option === 'client') { setClient('') }
            if (option === 'buildnumber') { setBuildingNumber('') }
            if (option === 'dek') { setDekOption('') }
            if (option === 'hourtype') { setHourType('') }
            if (option === 'food') { setStayAndFood('') }
            if (option === 'note') { setNote('') }
            if (option === 'hours') { setWorkedHours('') }
            if (option === 'all') { setNewRegistrationObject({klant:0,locatie:0,bouwnummer:0,dek:0,uren_soort:0,aantal_uren:0,opmerking:0}); } 
        })
    }

    //Handling when location is changed
    const handleChangeLocation = (event) => {
        const selectedOption = event.target.value;
        setLocation(selectedOption);
        var clients = [];
        data.map((option, index) => {
            if (selectedOption === option.value) {
                clients = option.klanten;
            }
        })
        emptyValues(['client','buildingnumber','dek']);
        setClientOptions(setDropdownOptions(clients));
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.locatie = selectedOption; obj.klant = 0; obj.bouwnummer = 0; obj.dek = 0; obj.uren_soort = 0;
        setNewRegistrationObject(obj);
    }

    //Handling when client is changed
    const handleChangeClient = (event) => {
        //Assign chosen value to state
        const selectedOption = event.target.value;
        setClient(selectedOption);
        var buildingNumbers = [];
        var dekken = [];
        var hourtypes = [];
        data.map((option, index) => {
            if (location === option.value) {
                option.klanten.map((option, index) => {
                    if (selectedOption === option.value) {
                        buildingNumbers = option.bouwnummers;
                        dekken = option.dekken;
                        hourtypes = option.urensoorten;
                    }
                })
            }
        })
        emptyValues(['buildingnumber','dek']);
        setBuildingNumbersOptions(setDropdownOptions(buildingNumbers));
        setDekOptions(setDropdownOptions(dekken));
        setHourTypeOptions(setDropdownOptions(hourtypes));
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.klant = selectedOption; obj.bouwnummer = 0; obj.dek = 0; obj.uren_soort = 0;
        setNewRegistrationObject(obj);
    }

    const handleChangeBuildingNumber = (event) => {
        setBuildingNumber(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.bouwnummer = event.target.value;
        setNewRegistrationObject(obj);
    }
    const handleChangeDek = (event) => {
        setDekOption(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.dek = event.target.value;
        setNewRegistrationObject(obj);
    }
    const handleChangeHourType = (event) => {
        setHourType(event.target.value);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.uren_soort = event.target.value;
        setNewRegistrationObject(obj);
    }
    const handleChangeStayAndFood = (event) => {
        setStayAndFood(event.target.value);
    }
    const handleChangeNote = (event) => {
        setNote(event.target.value);
         //Create new object and assign
         var obj = newRegistrationObject;
         obj.opmerking = event.target.value;
         setNewRegistrationObject(obj);
    }
    const handleWorkedHours = (event) => {
        var number = (Math.round(event.target.value * 4) / 4).toFixed(2);
        setWorkedHours(number);
        //Create new object and assign
        var obj = newRegistrationObject;
        obj.aantal_uren = number;
        setNewRegistrationObject(obj);
    }

    //Submit registration
    const submitRegistration = async () => {
        //Validate fields
        if (!newRegistrationObject.locatie) { notification('error', 'Select a location'); return; }
        if (!newRegistrationObject.klant) { notification('error', 'Select a client'); return; }
        if (!newRegistrationObject.bouwnummer) { notification('error', 'Select a construction number'); return; }
        if (!newRegistrationObject.dek) { notification('error', 'Select a deck'); return; }
        if (!newRegistrationObject.uren_soort) { notification('error', 'Select an hour type'); return; }
        if (!submitDate) { notification('error', 'Select a date'); return; }
        if (!stayAndFood) { notification('error', 'Select food/accomodation'); return; }
        if (!workedHours || workedHours === '0.00') { notification('error', 'Fill in the worked hours'); return; }
        if (afgeboekt) { notification('error', 'This day is already submitted'); return; }

        if (dataToEdit) {
            setIsUpdating(true);
        }


        var dataObj = '[' + JSON.stringify(newRegistrationObject) + ']';
        if (!dataToEdit) { setOpenBackdrop(true) }


        //Call API
        try {
            const response = dataToEdit ? 
            await api.post('api/v2/registratie?datum=' + submitDate + '&overnachting=' + stayAndFood + '&rijen=' + dataObj + '&rij=' + dataToEdit.index)
            :
            await api.post('api/v2/registratie?datum=' + submitDate + '&overnachting=' + stayAndFood + '&rijen=' + dataObj);
            // Success
            if (response.data.success) {
                getRegistrations(submitDate);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {}
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
    }


    return(
        <div className="register-form">

            {/* Date field */}
            <div className="form-field date">
                {
                    dataToEdit ?
                    <DropdownGhost label={dataToEdit.date} />
                    :
                    <Datepicker date={registerDate} changeDate={handleDateChange} />
                }
            </div>

            {/* Location field */}
            <div className="form-field">
                { locationOptions.length > 0 ? 
                <Dropdown placeholder="Which location?" value={location} label="Which location?" onChange={handleChangeLocation} source={locationOptions} modify={dataToEdit ? true : false}/> 
                :
                <Skeleton height={40} /> }
            </div>

            {/* Client field */}
            <div className="form-field">
                {locationOptions.length > 0 && location ?
                    <Dropdown placeholder={!location ? "Select a location first" : "Which client?"} value={client} label="Which client?" onChange={handleChangeClient} source={clientOptions} modify={dataToEdit ? true : false} />
                    :
                    <div>
                        {
                            locationOptions.length === 0 ?
                                <Skeleton height={40} />
                                :
                                <DropdownGhost label="Select a location first" />
                        }
                    </div>
                }
            </div>

            {/* Building number field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 && client ?
                        <Dropdown placeholder={!client ? "Select a client first" : "Which construction number?"} value={buildingNumber} label="Which construction number?" onChange={handleChangeBuildingNumber} source={buildingNumbersOptions} modify={dataToEdit ? true : false} />
                        :
                        <div>
                            {
                                locationOptions.length === 0 ?
                                    <Skeleton height={40} />
                                    :
                                    <DropdownGhost label="Select a client first" />
                            }
                        </div>
                }
            </div>

            {/* Dek field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 && client ?
                        <Dropdown placeholder={!client ? "Select a client first" : "Which deck?"} value={dekOption} label="Which deck?" onChange={handleChangeDek} source={dekOptions} modify={dataToEdit ? true : false} />
                        :
                        <div>
                            {
                                locationOptions.length === 0 ?
                                    <Skeleton height={40} />
                                    :
                                    <DropdownGhost label="Select a client first" />
                            }
                        </div>
                }
            </div>

            {/* Hourtype field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 && client ?
                        <Dropdown placeholder={!client ? "Select a client first" : "Hour type"} value={hourType} label="Hour type" onChange={handleChangeHourType} source={hourTypeOptions} />
                        :
                        <div>
                            {
                                locationOptions.length === 0 ?
                                    <Skeleton height={40} />
                                    :
                                    <DropdownGhost label="Select a client first" />
                            }
                        </div>

                }
            </div>

            {/* Stay and food field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 ?
                        <Dropdown placeholder="Food/accommodation" value={stayAndFood} label="Food/accommodation" onChange={handleChangeStayAndFood} source={stayAndFoodOptions} />
                        :
                        <Skeleton height={40} />
                }
            </div>

            {/* Stay and food field */}
            <div className="form-field">
                {
                    locationOptions.length > 0 ?
                        <textarea value={note} onChange={handleChangeNote} placeholder="Optional notes" />
                        :
                        <Skeleton height={40} />
                }
            </div>

            {/* Submit form */}
            <div className="registration-submit">
                <input type="number" value={workedHours} onBlur={handleWorkedHours} onChange={(e) => setWorkedHours(e.target.value)} placeholder="00:00" step="0.25" />
                {
                    isUpdating ?
                        <TailSpin
                            height="30"
                            width="30"
                            color="#13a3f6"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                    :
                    <button onClick={submitRegistration}>
                        {
                            dataToEdit ?
                            <span>Save</span>
                            :
                            <span>Add hours</span>
                        }
                    </button>
                }
                
            </div>
            {
                !dataToEdit ?
                <button onClick={() => emptyValues(['location','client','buildingnumber','dek','hourtype','food','note','hours','all'])} className="reset">Reset</button>
                :
                null
            }
            <ToastContainer transition={Slide}/>
        </div>
    );
}

export default RegisterTimeForm;