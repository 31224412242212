import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//API
import api from '../../settings/AxiosSetup';

//3rd party
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../../css/style.css';
import '../../css/login/login.css';

const ChangePassword = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const key = searchParams.get("key");
    const login = searchParams.get("login");
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
        if (loggedIn === 'true') { navigate('/') }
        if (!key && !login) {
            navigate('/login');
        }
    }, []);
    
    //Variables
    const [password, setPassword] = useState('');
    const [loading, setLoading]  = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    //Lost password function
    const postChangePassword = async (key, login) => {
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await api.post('api/v2/wachtwoord-wijzigen?login=' + login + '&key=' + key + '&wachtwoord=' + password);
            // Success
            if (response.data.success) {
              setSuccessMessage(response.data.message);
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 404) {
                setErrorMessage(error.response.data.message);
              }
              if (error.response.status === 403) {
                setErrorMessage(error.response.data.message);
              }
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
          setLoading(false);
    }

    if (loggedIn !== 'true') {
        return(
            <div className="login">
                <div className="login-form">
                    <img src={require('../../assets/images/logo.png')} alt="Gemini Teak" />
                    <div className="form">

                        {
                            !successMessage ?
                            <input
                                className="form-field"
                                type="password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Nieuw wachtwoord"
                            />
                            :
                            <p className="success">{successMessage}</p>
                        }
                        
                        
                        {
                            !successMessage ?
                            <button className="submit-login" onClick={() => postChangePassword(key, login)}>
                            {
                                !loading ?
                                <span>Opslaan</span>
                                :
                                <TailSpin
                                height="20"
                                width="20"
                                color="white"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                wrapperClass=""
                                visible={true}
                            />
                            }
                            </button>
                            :
                            null
                        }
                        

                        <p className="form-link" onClick={() => navigate("/login")}>Terug naar inloggen</p>

                        {
                            errorMessage ?
                            <p className="error">{errorMessage}</p>
                            :
                            null
                        }
                        
                        
                    </div>
                </div>
                <div className="image"></div>
            </div>
        );
    }
}

export default ChangePassword;