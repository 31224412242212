import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

//API
import api from '../../settings/AxiosSetup';

//Components
import Header from '../../components/nav/header';
import BottomMenu from '../../components/nav/bottomMenu';

const Verlof = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
        if (loggedIn !== 'true') { navigate('/login') };
    }, []);

    //Logout function
    const logout = () => {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ingelogd");
        navigate("/login");
    }

    return(
        <div>
            <Header />
            <div className="main">
                <div className="container">
                    <div className="content">
                        <div className="register">
                            <div className="register-form">
                                <h2>Verlof aanvraag</h2>
                            </div>
                            <div className="days">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomMenu />
        </div>
        
    );
}

export default Verlof;