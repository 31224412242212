// Libraries
import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://gemini.groeier.dev/wp-json/'
});

instance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('bearerToken');
        let bearer = '';
        if (token) {
            bearer = token;
        }
        if (bearer !== ''){
            config.headers.common['Authorization'] = `Bearer ${token}`;
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default instance;