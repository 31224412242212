import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//API
import api from '../../settings/AxiosSetup';

//3rd party
import { TailSpin } from  'react-loader-spinner';

//Styles
import '../../css/style.css';
import '../../css/login/login.css';

const Login = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
        if (loggedIn === 'true') { navigate('/') }
    }, []);

    //Variables
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading]  = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //Login function
    const submitLogin = async () => {
        setLoading(true);
        setErrorMessage('');
        if (!user) { setErrorMessage('E-mailadres is verplicht.'); setLoading(false); return; }
        if (!password) { setErrorMessage('Wachtwoord is verplicht.'); setLoading(false); return; }
        try {
            const response = await api.post('jwt-auth/v1/token?username=' + user + '&password=' + password + '');
            // Success
            if (response.data.token) {
              localStorage.setItem("bearerToken", response.data.token);
              localStorage.setItem("ingelogd", true);
              localStorage.setItem("voornaam", response.data.profile.user_first_name);
              navigate("/");
            }
          } catch (error) {
            // Error
            if (error.response) {
              if (error.response.status === 401) {}
              if (error.response.status === 403) {
                setErrorMessage('Gebruikersnaam/wachtwoord is niet correct.');
              }
              if (error.response.status === 429) {}
              if (error.response.status === 500) {}
            }
          }
          setLoading(false);
    }

    //Hit enter function
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            submitLogin();
        }
    };

    if (loggedIn !== 'true') {
        return(
            <div className="login">
                <div className="login-form">
                    <img src={require('../../assets/images/logo.png')} alt="Gemini Teak" />
                    <div className="form">
                        <input
                            className="form-field"
                            type="email" 
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="E-mailadres"
                        />
                        <input
                            className="form-field"
                            type="password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Wachtwoord"
                        />
                        

                        <button className="submit-login" onClick={submitLogin}>
                        {
                            !loading ?
                            <span>Inloggen</span>
                            :
                            <TailSpin
                            height="20"
                            width="20"
                            color="white"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            wrapperClass=""
                            visible={true}
                        />
                        }
                        </button>

                        <p className="form-link" onClick={() => navigate("/wachtwoord-vergeten")}>Wachtwoord vergeten?</p>

                        {
                            errorMessage ?
                            <p className="error">{errorMessage}</p>
                            :
                            null
                        }
                        
                        
                    </div>
                </div>
                <div className="image"></div>
            </div>
        );
    }
}

export default Login;