import React from 'react';

//3rd party
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Dropdown = (props) => {

    const invalid = props.invalid;
    const placeholder = props.placeholder;
    const value = props.value;
    const label = props.label;
    const source = props.source;
    const handleChange = props.onChange;
    const modify = props.modify;

    return (
        <FormControl sx={{ width: '100%' }} error={invalid ? true : false}>
            <InputLabel style={{ fontFamily: 'RalewayRegular' }}>{placeholder}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={handleChange}
            >
                {
                    source.length > 0 ?
                    source.map((option, index) => {
                        if (!option.inactief) {
                            return <MenuItem key={index} value={option.value} style={{ fontFamily: 'RalewayRegular' }}>{option.label}</MenuItem>
                        } if (modify && option.inactief) {
                            return <MenuItem key={index} value={option.value} style={{ fontFamily: 'RalewayRegular' }} disabled={modify}>{option.label}</MenuItem>
                        }
                    })
                    :
                    <p className="no-options">Geen opties gevonden...</p>
                }
            </Select>
        </FormControl>

    );
}

export default Dropdown;