import React from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';

//Styles
import './css/bottom-menu.css';

const BottomMenu = () => {

    const navigate = useNavigate();

    //Logout function
    const logout = () => {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ingelogd");
        localStorage.removeItem("formdata");
        navigate("/login");
    }

    return(
        <div className="bottom-nav">
            <div className="nav-item" onClick={() => navigate("/")}>
                <Icon.ClockHistory />
                <p>Day state</p>
            </div>
            <div className="nav-item" onClick={() => navigate("/verlof")}>
                <Icon.Receipt />
                <p>Leave of absence</p>
            </div>
            <div className="nav-item" onClick={logout}>
                <Icon.BoxArrowInRight />
                <p>Logout</p>
            </div>
        </div>
        
    );
}

export default BottomMenu;