import React from 'react';

//3rd party
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

//Style
import './css/dayrow.css';

const DayRowSkeleton = () => {

    return (
        <div className="loader">
            <div>
                <Skeleton height={25} width={200} />
                <Skeleton height={20} width={100} />
            </div>
            <div>
                <Skeleton height={25} width={90} />
            </div>
            <div>
                <Skeleton height={25} width={150} />
            </div>
        </div>
    );
}

export default DayRowSkeleton;