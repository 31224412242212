import React, { useState } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

//Styles
import './css/dayrow.css';

const DayRow = (props) => {

    const index = props.row;
    const postId = props.id;
    const date = props.date;
    const afgeboekt = props.afgeboekt;
    const locationId = props.locatie.value.toString();
    const locationName = props.locatie.label;
    const clientId = props.klant.value.toString();
    const clientName = props.klant.label;
    const hourTypeId = props.urensoort.value.toString();
    const hourType = props.urensoort.label;
    const dekId = props.dek.value.toString();
    const dek = props.dek.label;
    const bouwnummerId = props.bouwnummer.value.toString();
    const bouwnummer = props.bouwnummer.label;
    const aantal_uren = props.aantal_uren.value;
    const opmerking = props.opmerking.value;
    const overnachting_eten = props.overnachting_eten.value;
    const deleteRow = props.deleteRow;
    const renderModal = props.renderModal;
    const emptyData = props.emptyData;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return(
        <div className="line-item">
            {
                afgeboekt ?
                <div className="disabled"></div>
                :
                null
            }
            <div className="column-left">
                <div className="item-content">
                    <p className="title"><span className="location">{locationName}</span> - <span className="client">({clientName})</span>{opmerking ? <Tooltip title={opmerking} arrow><Icon.ChatLeftText /></Tooltip>: null}</p>
                    <ul className="badges">
                        <li>{bouwnummer}</li>
                        <li>{dek}</li>
                    </ul>
                </div>
            </div>
            <div className="column-right">
                <div className="item-type">
                    <span className="type-label">{hourType}</span>
                </div>
                <div className="item-options">
                    <div className="time">
                        <span className="hours">{aantal_uren}</span>
                    </div>
                    {
                        !afgeboekt ?
                        <div className="options">
                            <Icon.ThreeDotsVertical aria-describedby={id} variant="contained" onClick={handleClick} />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                                }}
                            >
                                <span className="option delete" onClick={() => {deleteRow(postId, index + 1); handleClose()}}><Icon.Trash /> Remove</span>
                                <span className="option modify" onClick={() => {renderModal(postId, index + 1, date, locationId, clientId, bouwnummerId, dekId, hourTypeId, overnachting_eten, aantal_uren, opmerking); handleClose()}}><Icon.Pen /> Change</span>
                            </Popover>
                        </div>
                        :
                        null
                    }
                    
                </div>
            </div>
        </div>
    );
}

export default DayRow;