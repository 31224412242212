import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';

//Styles
import './css/header.css';

const Header = () => {

    const navigate = useNavigate();

    //Logout function
    const logout = () => {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ingelogd");
        localStorage.removeItem("formdata");
        navigate("/login");
    }

    return(
        <div className="header">
            <div className="header-container">
                <div className="logo">
                    <img src={require('../../assets/images/logo.png')} alt="Gemini Teak" onClick={() => navigate("/")} />
                </div>
                <div className="nav">
                    <ul>
                        <li className="cta" onClick={() => navigate("/")}><Icon.ClockHistory />Day state</li>
                        <li className="normal" onClick={() => navigate("/verlof")}><Icon.Receipt />Leave of absence</li>
                        <li className="with-icon logout" onClick={logout}>Logout <Icon.BoxArrowInRight /></li>
                    </ul>
                </div>
            </div>
        </div>
        
    );
}

export default Header;