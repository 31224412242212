import React, { useState } from 'react';

//3rd party
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


//Styles
import './css/datepicker.css';

const Datepicker = (props) => {

    const changeDate = props.changeDate;
    const [value, setValue] = useState(props.date);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            changeDate(newValue);
          }}
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    );
}

export default Datepicker;